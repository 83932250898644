<template>
  <v-data-table :items="users" :headers="headers" class="elevation-1">
    <template v-slot:items="props">
      <td>{{ props.item.id }}</td>
      <td>{{ props.item.name }}</td>
      <td>{{ props.item.password }}</td>
      <td>{{ props.item.visibleName }}</td>
      <td>{{ props.item.userName }}</td>
      <td>{{ props.item.status }}</td>
      <td>{{ props.item.erpCode }}</td>
      <td>{{ props.item.erpName }}</td>
      <td>{{ props.item.mail }}</td>
      <td>{{ props.item.recordTime }}</td>
      <td>{{ props.item.lastEntryTime }}</td>
      <td>{{ props.item.id }}</td>
      <div>
        <v-icon small @click="deleteItem(item)">
          {{ icons.mdiDeleteOutline }}
        </v-icon>
      </div>

      <!-- Add more columns as needed -->
    </template>
    <template #[`props.item.actions`]="{ item }">
      <v-icon small class="me-2" @click="editItem(item)">
        {{ icons.mdiPencilOutline }}
      </v-icon>
      <v-icon small @click="deleteItem(item)">
        {{ icons.mdiDeleteOutline }}
      </v-icon>
    </template>
  </v-data-table>
</template>
<script>
import { mdiDeleteOutline, mdiPencilOutline } from '@mdi/js'
export default {
  data() {
    return {
      icons: {
        mdiDeleteOutline,
        mdiPencilOutline,
      },
      users: [
        {
          id: '1',
          name: 'erkal.ozurun',
          password: '123456',
          visibleName: 'Erkal ÖZÜRÜN',
          userName: 'ADMIN',
          status: 'A',
          erpCode: '120.01.0149',
          erpName: '120.01.0149',
          mail: 'john@example.com',
          phone: '+905547224645',
          recordTime: '25.05.2023 08:35',
          lastEntryTime: '25.05.2023 08:35',
        },
        {
          id: '1',
          name: 'John Doe',
          password: '123456',
          visibleName: 'Erkal ÖZÜRÜN',
          userName: 'ADMIN',
          status: 'A',
          erpCode: '120.01.0149',
          erpName: '120.01.0149',
          mail: 'john@example.com',
          phone: '+90 554 722 46 45',
          recordTime: '25.05.2023 08:35',
          lastEntryTime: '25.05.2023 08:35',
        },
        {
          id: '1',
          name: 'John Doe',
          password: '123456',
          visibleName: 'Erkal ÖZÜRÜN',
          userName: 'ADMIN',
          status: 'A',
          erpCode: '120.01.0149',
          erpName: '120.01.0149',
          mail: 'john@example.com',
          phone: '+90 554 722 46 45',
          recordTime: '25.05.2023 08:35',
          lastEntryTime: '25.05.2023 08:35',
        },
        {
          id: '1',
          name: 'John Doe',
          password: '123456',
          visibleName: 'Erkal ÖZÜRÜN',
          userName: 'ADMIN',
          status: 'A',
          erpCode: '120.01.0149',
          erpName: '120.01.0149',
          mail: 'john@example.com',
          phone: '+90 554 722 46 45',
          recordTime: '25.05.2023 08:35',
          lastEntryTime: '25.05.2023 08:35',
        },
        {
          id: '1',
          name: 'John Doe',
          password: '123456',
          visibleName: 'Erkal ÖZÜRÜN',
          userName: 'ADMIN',
          status: 'A',
          erpCode: '120.01.0149',
          erpName: '120.01.0149',
          mail: 'john@example.com',
          phone: '+90 554 722 46 45',
          recordTime: '25.05.2023 08:35',
          lastEntryTime: '25.05.2023 08:35',
        },
        {
          id: '1',
          name: 'John Doe',
          password: '123456',
          visibleName: 'Erkal ÖZÜRÜN',
          userName: 'ADMIN',
          status: 'A',
          erpCode: '120.01.0149',
          erpName: '120.01.0149',
          mail: 'john@example.com',
          phone: '+90 554 722 46 45',
          recordTime: '25.05.2023 08:35',
          lastEntryTime: '25.05.2023 08:35',
        },
        { name: 'Jane Smith', email: 'jane@example.com' },
        // Add more user objects as needed
      ],
      headers: [
        { text: 'ID', value: 'id' },
        { text: 'Adı', value: 'name' },
        { text: 'Şifre', value: 'password' },
        { text: 'Görünen Ad', value: 'visibleName' },
        { text: 'Kullanıcı Tipi', value: 'userName' },
        { text: 'Statü', value: 'status' },
        { text: 'ERP Müşteri Kodu', value: 'erpCode' },
        { text: 'ERP Müşteri Adı', value: 'erpName' },
        { text: 'E-mail', value: 'mail' },
        { text: 'Cep Tel', value: 'phone' },
        { text: 'Kayıt Zamanı', value: 'recordTime' },
        { text: 'Son Giriş Zamanı', value: 'lastEntryTime' },
        { text: 'Actions', value: 'actions' },
        // Add more headers as needed
      ],
    }
  },
}
</script>
